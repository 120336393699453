@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.aboutSec {
    padding: 0 100px;
}

.aboutSec h3 {
    font-weight: 500;
    /* color: #a65445; */
    color: #041434;
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
    margin-bottom: 0;
    border-bottom: 1px dotted;
    width: fit-content;
}

.aboutSecText {
    font-weight: 300;
    color: #17252a;
    font-size: 17px;
    line-height: 27px;
    /* font-family: 'Open Sans', sans-serif; */
}

.joinUs {
    background-color: #1c395f;
    /* height: 22vh; */

    align-self: flex-start;
    display: flex;
    height: 340px;
    position: relative;
}

.joinUs img {
    width: 100%;
    height: auto;
    object-fit: cover;
    opacity: 0.5;
}

.joinUs p {
    line-height: 27px;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    text-shadow: 3px 3px 4px #000;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    z-index: 3;
    padding: 0 50px;
}

@media (max-width: 1024px) {
    .aboutSec {
        padding: 0 80px;
    }
}

@media (max-width: 786px) {
    .aboutSec {
        padding: 0 50px;
    }

    .joinUs p {
        text-align: center;
    }

}

@media (max-width: 435px) {
    .aboutSec {
        padding: 0 20px;
    }

    .joinUs p {
        font-size: 18px;
        padding: 15px;
    }

}