.contactHeading {
  margin-top: 95px;
  text-align: center;
  padding-bottom: 5px;
  /* width: 100vw; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #def2f1; */
}

.contactHeading h1 {
  /* color: #a65445; */
  color: #041434;
  /* font-family: 'Poppins', sans-serif; */
  font-weight: 600;
  font-size: 50px;
  line-height: 42px;
}

.formSec {
    display: flex;
    padding: 50px 100px;
    justify-content: space-around;
}

.contactDetails {
    display: flex;
    flex-direction: column;
}

.cDetail {
    display: flex;
    justify-content: flex-start;
    gap: 25px;
    align-items: center;
    margin: 10px 0;
}

.cIcon {
    padding: 10px;
    border: 2px solid;
    border-radius: 50%;
    /* background-color: #5445a6; */
    background-color: #1c395f;
}

.cuIcon {
  color: #fff;
    align-self: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    align-items: center;
}

.ciText h3, .ciText p{
    margin: 0;
}

/* .ciText h3 {
  font-family: 'Open Sans', sans-serif;
} */

.form {
    width: 45%;
    display: flex;
    flex-direction: column;
    padding: 25px;
    box-shadow: 4px 0px 10px -2px rgba(33, 40, 51, 0.6),
               -4px 0px 10px -2px rgba(33, 40, 51, 0.6);
    
    /* background-color: #5445a6; */
    background-color: #1c395f;
    border-radius: 10px;
  }
  
  .formDesc {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
  }
  
  .formDesc h3 {
    margin: 0;
  }
  
  .formDesc h4 {
    font-weight: 300;
    font-size: 16px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
  
  .cFContent {
    padding: 10px;
    border: none;
    background-color: aliceblue;
    width: 90%;
    font-size: 1rem;
    border-radius: 15px;
  }
  
  .red {
    display: contents;
    color: red;
  }
  
  .contactBtn {
    background-color: #fff;
    color: #000;
    font-weight: 600;
    box-shadow: 8px 14px 20px rgba(0, 0, 0, .6);
  }

  .contactFormLabel {
    border: none;
    display: flex;
    width: 92%;
    color: #fff;
    font-weight: 600;
  }

  @media (max-width: 1024px) {
    .formSec {
      padding: 50px 50px;
    }

    .contactDetails {
      width: 60%;
    }
  }

  @media (max-width: 435px) {
    .formSec {
      flex-direction: column;
      padding: 20px 20px;
    }

    .contactDetails {
      width: auto;
      order: 2;
    }
    
    .form {
      width: auto;
      margin-top: 15px;
    }

    .contactHeading h1 {
      margin-bottom: 0;
    }

    .cuIcon {
      width: 30px;
      height: 30px;
    }
  }