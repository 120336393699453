.whyChooseUs {
    padding: 20px 100px;
    color: #fff;
    flex-direction: column;
    justify-content: space-evenly;
    display: flex;
    position: relative;
  }
  
  .whyUsTitle {
    align-self: center;
  }
  
  .whyUsTitle h2 {
    color: #041434;
    /* font-family: 'Open Sans', sans-serif; */
    font-weight: 600;
    font-size: 42px;
    line-height: 42px;
    text-transform: uppercase;
  }

  .wcuList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Create three equally spaced columns */
    grid-gap: 20px;
    /* Adjust gap between grid items */
    justify-content: center;
  }
  
  .wcuListItem {
    /* width: calc(26% - 20px); */
    background: #1c395f;
    /* height: 225px; */
    color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    /* justify-content: space-between; */
  }
  
  /* .wcuListItem:hover {
    transform: perspective(500px) rotateX(5deg);
  } */
  
  .wcuListItem h4 {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    align-self: center;
    text-align: center;
    height: 45px;
    color: #f5f5f5;
  }
  
  .wcuListItem p {
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    /* font-family: 'Poppins', sans-serif; */
  }
  
  .icon {
    align-self: center;
    /* background-color: bisque; */
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    align-items: center;
  }
  
  @media (max-width: 1024px) {
    .whyChooseUs {
      padding: 20px 80px;
    }

    .wcuList {
      margin-bottom: 10px;
    }
  
    .wcuListItem h4 {
      font-size: 18px;
    }
  
    .wcuListItem p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 768px) {
  
    .whyChooseUs {
      padding: 0 50px;
    }
  
    .wcuList {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .whyUsTitle h2 {
      text-align: center;
    }
  
    .wcuListItem h4 {
      font-size: 16px;
      height: fit-content;
    }
  }
  
  
  @media (max-width: 430px) {
    .wcuList {
      grid-template-columns: repeat(1, 1fr);
    }
  
    .whyChooseUs {
      padding: 20px;
    }
  
    .wcuListItem h4 {
      font-size: 14px;
      height: fit-content;
    }
  
    .wcuListItem p {
      font-size: 12px;
    }
  }