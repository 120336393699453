.testimonial {
    display: flex;
    height: auto;
    justify-content: space-evenly;
    flex-direction: column;
    color: #000;
    position: relative;
    margin: 25px 0;
    padding: 0 100px;
  }
  
  .testimonialDesc {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .testimonial h3 {
    display: flex;
    justify-content: center;
    margin: 0;
    color: #155f98;
  }
 
  .testimonail-contnet {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #5445a6; */
    background: linear-gradient(to top,#6a58ca,#8174c4);
    box-shadow: 1px 1px 10px #000000;
    /* height: 200px; */
  }
  
  .people-quotes {
    height: 250px;
    justify-content: center;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    padding: 30px;
  }
  
  .quote-icon {
    background-color: bisque;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    align-items: center;
  }
  
  .quote p {
    color: #def2f1;
    font-weight: 400;
    font-size: 20px;
    margin: 0;
    text-align: center;
  }
  
  .person-detail h4 {
    color: #d6654f;
    font-weight: 600;
    font-size: 24px;
    margin: 20px 0;
  }

  .qIcon {
    font-size: 25px;
  }

  @media only screen and (max-width: 1024px) { 
    .testimonial {
      padding: 0 80px;
    }
  }

  @media only screen and (max-width: 768px) { 
    .testimonial {
      padding: 0 50px;
    }

    .quote p {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 430px) { 
    .testimonial {
      padding: 0 20px;
    }
  }