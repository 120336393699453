.navbar {
  height: 95px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  /* font-family: Maison Neue Bold, sans-serif; */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 5;
  box-shadow: 3px 7px 15px rgba(0,0,0,.1);
}

.nav-container {
  width: 100%;
  max-width: 1120px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  height: 50px;
  gap: 30px;
}

.nav-item {
  margin: 0 10px;
  position: relative;
  height: 95px;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;
}

.nav-item a {
  text-decoration: none;
  color: #000;
  /* font: normal 500 21px / 25px "Teko", Helvetica, Arial, Verdana, sans-serif; */
  font-size: 18px;
  font-weight: 700;
  transition: all 0.3s ease-out;
  text-transform: uppercase;
}

.nav-item a:hover {
  transform: translateY(-3px); 
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: #000;
}

.logo img {
  max-height: 95px;
  max-width: 100%;
}

.nav-link {
  text-decoration: none;
  color: #fff;
  padding: 10px;
}

.mobile-menu-button {
  display: none;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.submenu-image {
  display: flex;
  position: relative;
  top: 20px;
  left: 30px;
}

.mblMEnu {
  display: none;
}

.quote-btn {
  border: none;
  padding: 15px;
  border-radius: 5px;
  background-color: #1c395f;
  color: #def2f1;
  font-family: 'Gotham';
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all ease-in-out 0.6s;
}

.quote-btn:hover {
  background-color: #d0d9e1;
  color: #1c395f;
}

@media (max-width: 1024px) {
  .nav-item a {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {

  .mobile-menu-button {
    display: block;
    /* Show the button on mobile and tablet screens */
    position: absolute;
    /* top: 10px; */
    right: 10px;
    z-index: 100;
    color: black;
    transition: transform 0.3s ease;
  }

  .mobile-menu-button.open {
    transform: translateX(-100%);
  }

  .nav-list {
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 96px;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: #fff;
    transform: translateX(-100%);
    transition: transform 0.7s ease, opacity 0.7s ease;
    min-height: 250px;
    gap: 10px;
  }

  .nav-item {
    height: auto;
    margin: 10px 0;
    /* border-bottom: 1px solid #354256; */
    width: 85%;
    justify-content: space-between;
    color: #354256;
    border-bottom: 1px solid #00000033;
  }

  .nav-item a {
    font-size: 1.5rem;
    padding: 5px 0;
    color: #17252a;
  }

  .nav-list.open {
    transform: translateX(0);
    opacity: 1;
  }

  .dropdown-menu {
    position: static;
    background-color: #555;
    max-height: none;
  }

  .nav {
    display: none;
  }

  .mblMEnu {
    display: flex;
  }

  .qBtn {
    border: none;
  }

  .nav-container {
    justify-content: center;
  }

  .dropdown-menu {
    position: fixed !important;
    top: -2px !important;
    width: 100vw !important;
    height: 100% !important;
    align-items: center !important;
    padding: 0 !important;
  }

  .dropdown-menu li {
    display: flex;
    justify-content: center;
  }

  .dropdown-menu li a {
    width: auto;
  }
}
