.sitemap{
    margin-top: 90px;
    padding: 20px 100px;
}

.siteLink {
    text-decoration: none;
    color: red;
}

.scItem h2 {
    margin-bottom: 5px;
}

.scItem h3 {
    margin-bottom: 5px;
}

.siteLink:hover {
    text-decoration: underline;
}

.scIsubItem {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}

@media only screen and (max-width: 768px) {
    .sitemap{
        padding: 20px 50px;
    }
}

@media only screen and (max-width: 430px) {
    .sitemap{
        padding: 20px 20px;
    }

    .scItem h3 {
        font-size: 14px;
    }

    .siteLink {
        font-size: 12px;
    }
}

